<template>
  <div>
    <Crumbs></Crumbs>
    <!-- <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="fr">
          <el-button type="primary" icon="el-icon-plus" @click="add()"
            >新增</el-button
          >
        </div>
      </div> -->

    <div class="mainList">
      <div class="fl department_div">
        <el-tree
          class="deptTree"
          default-expand-all
          node-key="id"
          :data="deptList"
          :props="defaultProps"
          :expand-on-click-node="false"
          @node-click="handleNodeClick"
          :render-content="renderContent"
          :highlight-current="true"
        >
        </el-tree>
      </div>
      <div class="table_right">
        <div class="mainList_operation clearfloat">
          <!-- <div class="mainList_operation_search">
            <el-input
              placeholder="请输入内容"
              clearable
              v-model="parameter.val"
              class="input-with-select"
            >
              <el-select v-model="conttll" slot="prepend" placeholder="请选择">
                <el-option label="用户名" value="loginName"></el-option>
                <el-option label="姓名" value="userName"></el-option>
              </el-select>
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="pageChangeHandler(1)"
              ></el-button>
            </el-input>
          </div> -->
          <div class="mainList_operation_fr">
            <el-button type="primary" icon="el-icon-plus" @click="add">新增</el-button>
          </div>
        </div>

        <div class="mainList_content">
          <div class="mainList_content_sub">
            <el-table
              ref="multipleTable"
              row-key="id"
              border
              :data="tableData"
              :default-expand-all="true"
              tooltip-effect="dark"
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
              height="string"
              v-loading="loading"
            >
              <el-table-column align="left" type="index" width="50" label="序号"></el-table-column>
              <el-table-column prop="department" align="center" label="部门"></el-table-column>
              <el-table-column prop="post" align="center" label="岗位"></el-table-column>
              <el-table-column prop="rank" align="center" label="职级" sortable min-width="110">
                <template slot-scope="scope">
                  {{ scope.row.rank | dict(rankType) }}
                </template>
              </el-table-column>
              <el-table-column
                prop="salary"
                align="center"
                label="薪资范围"
                sortable
                min-width="110"
              ></el-table-column>
              <el-table-column label="操作" align="center" width="70">
                <template slot-scope="scope">
                  <el-button type="text" class="text_Edit_Bgc" size="small" @click="edit(scope.row)"
                    >编辑</el-button
                  >
                  <!-- <el-button type="text" size="small" @click="del(scope)"
                    >删除</el-button
                  > -->
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="pageChangeHandler"
              :current-page="parameter.pageNow"
              :page-size="parameter.pageSize"
              prev-text="上一页"
              next-text="下一页"
              layout="total, prev, pager, next, slot, jumper"
              :total="parameter.total"
            >
              <span class="el-pagination__jump e_a_pagination">
                <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
                <span style="padding-top: 1px">条/页</span>
              </span>
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- <EditDialog width="600px" :isShow.sync="addDialog" @submit="submit">
      <div slot="title" class="title">
        {{ form.id ? "编辑职级" : "新增职级" }}
      </div>
      <div slot="content" class="content">
        <el-form label-width="7em" :model="form" ref="form">
          <el-form-item label="部门名称" prop="department" ref="department">
           <el-input
              v-model="form.department"
              placeholder="请输入部门名称"
            ></el-input>
        
          </el-form-item>
          <el-form-item label="岗位" prop="post" ref="post">
            <el-input v-model="form.post" placeholder="请输入职级"></el-input>
          </el-form-item>
          <el-form-item label="职级" prop="rank" ref="rank">
         <el-input v-model="form.rank" placeholder="请输入职级"></el-input>
          </el-form-item>
          <el-form-item label="薪资范围" prop="salary" ref="salary">
            <el-input
              v-model="form.salary"
              placeholder="请输入薪资范围"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </EditDialog> -->

    <el-dialog
      :title="form.id ? '编辑职级' : '新增职级'"
      :visible.sync="addDialog"
      width="30%"
      :before-close="cancelFn"
    >
      <el-form :rules="rules" label-width="7em" :model="form" ref="form">
        <el-form-item label="部门名称" prop="departmentId" ref="departmentId">
          <el-select class="item" v-model="form.departmentId" placeholder="请选择部门" clearable>
            <el-option
              v-for="item in deptList"
              :label="item.label"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="岗位" prop="post" ref="post">
          <el-input v-model="form.post" placeholder="请输入岗位"></el-input>
        </el-form-item>
        <el-form-item label="职级" prop="rank" ref="rank">
          <Dictionary v-model="form.rank" code="RANK_TYPE" placeholder="请选择职级" />
        </el-form-item>
        <el-form-item label="薪资范围" prop="salary" ref="salary">
          <el-input v-model="form.salary" placeholder="请输入薪资范围"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelFn">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { mapState } from 'vuex'
import { initTreeData } from '@/util/common'

export default {
  components: {
    // EditDialog: () => import("@/components/EditDialog.vue"),
    Crumbs: () => import('@/components/Crumbs.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    Treeselect: () => import('@riophae/vue-treeselect'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
      },
      pageSize: 0,
      form: {},
      tableData: [],
      loading: false,
      addDialog: false,
      deptList: [], // 部门 树形列表
      // 部门 树形对象 结构
      defaultProps: {
        children: 'children',
        label: 'deptName',
      },
      rankType: [],
      // conttll: "loginName",
      rules: {
        departmentId: [
          {
            required: true,
            message: '请选择部门',
            trigger: ['change'],
          },
        ],
        post: [
          {
            required: true,
            message: '请输入岗位',
            trigger: ['blur', 'change'],
          },
        ],
        rank: [
          {
            required: true,
            message: '请选择职级',
            trigger: ['change'],
          },
        ],
        salary: [
          {
            required: true,
            message: '请输入薪资范围',
            trigger: ['blur', 'change'],
          },
        ],
      },
      renderContent: function (h, { node, data, store }) {
        let addElement = arguments[0]
        if (data.level == 1) {
          return addElement('span', [
            addElement('i', { class: 'iconfont iconcompany iconDept' }),
            addElement('span', { class: 'deptName' }, arguments[1].node.label),
          ])
        } else if (data.level == 2) {
          return addElement('span', [
            addElement('i', { class: 'iconfont iconcangku iconDept' }),
            addElement('span', { class: 'deptName' }, arguments[1].node.label),
          ])
        } else if (data.level == 3) {
          return addElement('span', [
            addElement('i', { class: 'iconfont iconconnections iconDept' }),
            addElement('span', { class: 'deptName' }, arguments[1].node.label),
          ])
        } else if (data.level == 4) {
          return addElement('span', [
            addElement('i', { class: 'iconfont iconCustomermanagement iconDept' }),
            addElement('span', { class: 'deptName' }, arguments[1].node.label),
          ])
        }
      },
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData()
    this.getDeptList()
    this.$api.dict
      .listSysDictData('RANK_TYPE', true)
      .then(res => {
        this.rankType = res.data
        // PROJECT_STATUS 项目状态
        this.rankType = initTreeData(res.data)
      })
      .catch(err => {
        console.log(err)
      })
  },
  watch: {
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
  },
  methods: {
    cancelFn() {
      this.form = {}
      this.$refs.form.resetFields()
      this.addDialog = false
    },
    edit(row) {
      this.form = row.deepClone()
      this.addDialog = true
    },
    /** 加载 "部门" 列表数据 **/
    getDeptList() {
      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.deptList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    normalizer(node) {
      //去掉children=null的属性
      if (node.children == null || node.children == 'null') {
        delete node.children
      }
      return {
        id: node.id,
        label: node.deptName,
        children: node.children,
      }
    },
    /**
     * 点击 "部门" 树形列表
     * @param data      点击获取数据
     */
    handleNodeClick(data) {
      //   console.log(data);
      this.parameter.pageNow = 1
      this.parameter.departmentId = data.id
      this.getData()
    },
    getData() {
      this.loading = true
      this.$api.user
        .getRsSalaryManageList(this.parameter)
        .then(res => {
          this.loading = false
          this.tableData = res.data.records
          this.parameter.total = res.data.total
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    // del(scope) {
    //   this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       this.$api.user
    //         .deleteSalaryManage(scope.row.id)
    //         .then((res) => {
    //           this.$message.success("删除成功！");
    //           this.getData();
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //         });
    //     })
    //     .catch(() => {
    //       //用户取消
    //     });
    // },
    add() {
      this.form = {}
      this.addDialog = true
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id) {
            let obj = {
              departmentId: this.form.departmentId,
              id: this.form.id,
              post: this.form.post,
              rank: this.form.rank,
              salary: this.form.salary,
            }
            this.$api.user
              .updateRsSalaryManage(obj)
              .then(res => {
                this.$message.success('编辑成功！')
                this.$refs.form.resetFields()
                this.addDialog = false

                this.getData()
              })
              .catch(err => {})
          } else {
            let obj = {
              departmentId: this.form.departmentId,
              post: this.form.post,
              rank: this.form.rank,
              salary: this.form.salary,
            }
            this.$api.user
              .addRsSalaryManage(obj)
              .then(res => {
                this.$message.success('新增成功！')
                this.$refs.form.resetFields()
                this.addDialog = false

                this.getData()
              })
              .catch(err => {})
          }
        } else {
        }
      })
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.mainList {
  display: block;
}
.department_div {
  width: 246px;
  height: 100%;
  background-color: #ffffff;
  margin-right: 20px;
  padding: 10px 10px;
}

/deep/.el-tree {
  overflow: hidden;
}

.table_right {
  float: right;
  width: calc(100% - 266px);
  height: calc(100% - 90px);
}

.tran_style {
  white-space: nowrap;
  padding: 10px;
}
.no-autofill-pwd {
  ::v-deep .el-input__inner {
    -webkit-text-security: disc !important;
  }
}
/deep/.deptName {
  font-size: 14px;
}

/deep/.iconDept {
  color: #399ffb;
}

.deptTree {
  /* 改变被点击节点背景颜色，字体颜色 */
  /deep/ .el-tree-node:focus > .el-tree-node__content {
    background-color: #399ffb !important;
    color: #fff !important;
  }

  /* 改变被点击节点背景颜色，字体颜色 */
  /deep/ .el-tree-node:focus > .el-tree-node__content .iconDept {
    color: #fff !important;
  }

  /*节点失焦时的背景颜色*/
  /deep/ .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
    background-color: #399ffb !important;
    color: #fff !important;
  }

  /*节点失焦时的背景颜色*/
  /deep/ .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content .iconDept {
    color: #fff !important;
  }
}
</style>
